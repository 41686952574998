import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';
import { default as fitvids } from 'fitvids';

jQuery(document).ready(function () {
    jQuery('.o-banners.-isCarousel .bannersList').each(function () {
        let slickConf = {
            ...SLICK_CONF,
            arrows: true,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 7000,
            customPaging: function(slick, index) {
                return `<button type="button" data-role="none" role="button" tabindex="0">${index}</button>`;
            }
        };

        jQuery(this).not('.slick-initialized').slick(slickConf);
    });

    jQuery('.innerCarouselList').each(function () {
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 7000,
            centerMode: true,
            fade: true,
            lazyLoad: 'ondemand'
        };

        jQuery(this).slick(slickConf);
    });
    
    jQuery(".titleSlider").slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        fade: true,
        slidesToScroll: 1,
        controls:false,
        autoplay: true,
        autoplaySpeed: 2000,
    });

    fitvids('.banner .videoContainer');
});