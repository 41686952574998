// For the dining booking iframe
var loc = window.location.pathname;
var host = window.location.origin;
//var dir = loc.substring(0, loc.lastIndexOf('/'));
//var path = `${host}${dir}/content/zonal2.css`;
var path = `${host}/content/zonal2.css`;

window._fez_account = "3cd3857d-82cc-4e8f-bc3f-503f20e598e9";
window._fez_brand = "1";
window._fez_rest = "WKH,WKO";
window._fez_width = "100%";
window._fez_height = "100%";
window._fez_scrolling = "no";
window._fez_anal = "";
// window._fez_stylesheet = "https://dl.dropbox.com/s/7latnbe9ro4vgpl/Widget%20liveres%20.css";
window._fez_stylesheet = path;
window._fez_mobile = "no";
window._fez_ascript = "0";
window._fez_head = "";
window._fez_desc = "";
window._fez_offer = "";
window._fez_offerpopup = "1";
window._fez_partner = "";
window._fez_restaurantpicker = "";
window._fez_daymarketingpopup = "1";
window._fez_offerpicker = "";
window._fez_offerpopupchoosefullprice = "1";
window._fez_choosefullprice = "1";
window._fez_marketing = "";
window._fez_marketingtext = "";
