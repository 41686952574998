import 'jquery';
import { default as ScrollMagic } from 'scrollmagic';

jQuery(document).ready(function () {
    const scrollMagicConf = {
        reverse: false
    };
    
    const controller = new ScrollMagic.Controller();
    
    // Build Scenes
    // Prop Search
    if(jQuery(".m-property-search .blockInnerContainer").length > 0)
        new ScrollMagic.Scene(
            { 
                ...scrollMagicConf,
                triggerElement: ".m-property-search .blockInnerContainer" 
            })
            .setClassToggle(".m-property-search .blockInnerContainer", "animated")
            .addTo(controller);

    if(jQuery(".m-property-search .searchBlock").length > 0)
        new ScrollMagic.Scene({
            ...scrollMagicConf,
            triggerElement: ".m-property-search .searchBlock" 
        })
            .setClassToggle(".m-property-search .searchBlock", "animated")
            .addTo(controller);
    
    // Main Prop Search
    if(jQuery(".main-property-search .searchBlock").length > 0)
        new ScrollMagic.Scene({
            ...scrollMagicConf,
            triggerElement: ".main-property-search .searchBlock" 
        })
            .setClassToggle(".main-property-search .searchBlock", "animated")
            .addTo(controller);
    
    // Content Listings
    if(jQuery(".content-listings-block").length > 0)
        jQuery('.content-listings-block').each(function () {
            new ScrollMagic.Scene({
                ...scrollMagicConf,
                triggerElement: this 
            })
                .setClassToggle(this, "animated")
                .addTo(controller);
        });
    
    // Banners Block
    if(jQuery(".banners-block").length > 0)
        jQuery('.banners-block').each(function () {
            new ScrollMagic.Scene({
                ...scrollMagicConf,
                triggerElement: this 
            })
                .setClassToggle(this, "animated")
                .addTo(controller);
        });
});