import 'jquery';
import 'slick-carousel';
import {getArrowIcon, getSplatIcon, SLICK_CONF} from '../slick-common/slick-common';

jQuery(document).ready(function () {
    jQuery('.awards-block .contentListings.-isCarousel').each(function () {
        const that = this;
        const parentEl = jQuery(this).parents('.awards-block');
        const controlsEl =
            parentEl.length > 0 ?
                parentEl.find('.galleryControls') : [];

        let slickConf = {
            ...SLICK_CONF,
            arrows: true,
            dots: false,
            prevArrow: '<button type="button" class="slick-prev -splat -hoverSplat">' + getArrowIcon({ arrowType: 'prev' }) + getSplatIcon() + '</button>',
            nextArrow: '<button type="button" class="slick-next -splat -hoverSplat">' + getArrowIcon({ arrowType: 'next' }) + getSplatIcon() + '</button>',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            lazyLoad: 'ondemand',
        };

        if(controlsEl.length > 0) {
            slickConf.appendArrows = jQuery(controlsEl);
            slickConf.appendDots = jQuery(controlsEl);
        }

        if(jQuery(this).hasClass('slick-initialized'))
            jQuery(this).slick('unslick');

        jQuery(this).not('.slick-initialized').slick(slickConf);

        jQuery(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if(jQuery(that).parent().find('.galleryHeadList .activeItem .text').length > 0)
                jQuery(that).parent().find('.galleryHeadList .activeItem .text').text(nextSlide + 1);
        });
    });
});